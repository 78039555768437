.wp-block-appmaker-imagescroller > div > div {
	overflow: auto;
	white-space: nowrap;
	display: flex;
}
.wp-block-appmaker-imagescroller .view-more-button,
.wp-block-appmaker-imagescroller .view-more-button > div {
	width: unset !important;
}
.wp-block-appmaker-imagescroller > div > div > div > * {
	width: inherit;
}
.wp-block-appmaker-imagescroller .block-list-appender {
	display: flex;
	align-items: center;
	border-radius: 4px;
}
