.blocks-gallery-grid > div {
  width: 100%;
}
.blocks-gallery-grid > div > div {
  padding-right: 5px !important;
}
.blocks-gallery-grid > div > div > div {
  padding: 5px;
}
/* .wp-block-appmaker-grid-item {
  margin: 5px;
} */
.blocks-gallery-grid > div > div {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.has-1-item > div > div > div {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.has-2-item > div > div > div {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.has-3-item > div > div > div {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.has-4-item > div > div > div {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.has-5-item > div > div > div {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.image-container {
  width: fit-content;
  height: initial;
  margin: 2px 2px;
}
