/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
.control-label{
    @apply block mt-1
}
.form-control {
    @apply block w-full mt-1;
}
.form-group {
    @apply mt-3
}
.btn{

}
.btn-info {
@apply  mt-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}
.btn-success {
@apply w-full mt-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
}
div.appmaker-grid-cols-1  > div > div.block-editor-block-list__layout {
 @apply grid grid-cols-1   
}
div.appmaker-grid-cols-2 > div > div.block-editor-block-list__layout {
 @apply grid grid-cols-2   
}
div.appmaker-grid-cols-3 > div > div.block-editor-block-list__layout {
 @apply grid grid-cols-3  

}
div.appmaker-grid-cols-4 > div > div.block-editor-block-list__layout {
 @apply grid grid-cols-4   
}
.block-editor-inserter{
  @apply p-5
}
.components-dropdown.block-editor-inserter{
    @apply pt-32 bg-gray-200
}
 */
div[data-type='appmaker/row']
	div[data-type='appmaker/shopify-prebuild-product-scroller'] {
	width: 100%;
}
