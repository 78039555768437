.wp-block-appmaker-bsc-product-quality-block > div > div > div {
	overflow: auto;
	display: flex;
}
.wp-block-appmaker-bsc-product-quality-block > div > div > div > div > * {
	width: 9rem;
}
.wp-block-appmaker-bsc-product-quality-block .block-list-appender {
	display: block;
	margin: auto;
}
