.wp-block-appmaker-native-json,
.wp-block-appmaker-row,
div[data-type='appmaker/web-block-card'] {
	width: 100%;
}
.wp-block-appmaker-web-block-card .block-editor-block-list__layout {
	display: block !important;
}
.wp-block-appmaker-row .block-editor-block-list__layout,
.wp-block-appmaker-native-json .block-editor-block-list__layout {
	display: flex;
}
.flex-direction-column > div > .block-editor-block-list__layout {
	flex-direction: column;
}
.flex-direction-row > div > .block-editor-block-list__layout {
	flex-direction: row;
}
.align-flex-start > div > .block-editor-block-list__layout {
	align-items: flex-start;
}
.align-flex-end > div > .block-editor-block-list__layout {
	align-items: flex-end;
}
.align-flex-center > div > .block-editor-block-list__layout {
	align-items: center;
}
.justify-flex-start > div > .block-editor-block-list__layout {
	justify-content: flex-start;
}
.justify-flex-end > div > .block-editor-block-list__layout {
	justify-content: flex-end;
}
.justify-flex-center > div > .block-editor-block-list__layout {
	justify-content: center;
}
.justify-flex-space-between > div > .block-editor-block-list__layout {
	justify-content: space-between;
}
.justify-flex-space-evenly > div > .block-editor-block-list__layout {
	justify-content: space-evenly;
}
.flex-wrap-wrap > div > .block-editor-block-list__layout {
	flex-wrap: wrap;
}
.flex-wrap-nowrap > div > .block-editor-block-list__layout {
	flex-wrap: nowrap;
}
div.block-editor-block-list__layout
	.block-editor-block-list__block[data-type='appmaker/heading'] {
	position: unset;
	/* width: 100%; */
}
