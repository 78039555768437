.wp-block-appmaker-slider > div > div {
  overflow: auto;
  white-space: nowrap;
  display: flex;
}
.wp-block-appmaker-slider > div > div > div {
  width: 200px;
}
.wp-block-appmaker-slider > div > div > div > * {
  width: inherit;
}
