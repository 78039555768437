.react-datetime-picker__wrapper{
    border: none !important;
}
.react-datetime-picker input{
    padding: 2px 4px !important;
}
.react-datetime-picker input[type=number]::-webkit-outer-spin-button,
.react-datetime-picker input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datetime-picker input[type=number] {
    -moz-appearance:textfield;
}